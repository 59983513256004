// **********************************************
// Imports
// **********************************************

import React  from 'react';
import { Carousel } from 'react-bootstrap';
import { LANG_ENG }  from '../engine/Lang';

import './UiCarousel.css';

// **********************************************
// Class
// **********************************************

class UiCarouselProd extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.m_prodsEng = [
      {
        title: 'Fair tales Tea',
        details: 'Black ivan-chai in fairly tale box',
        descr: 'Ivan-chai made from kiprey leaves, fermented, packaged, in the hut-shaped souvenir box',
        price: '500 rub',
        shot: 'images/shot_hut.jpg',
        render: 'images/render_hut.png',
        alt: 'hut',
      },
      {
        title: 'Love Tea',
        details: 'Black ivan-chai in very lovely looking box',
        descr: 'Use this amazing ivan-chai as a gift for your loving one',
        price: '700 rub',
        shot: 'images/shot_heart.jpg',
        render: 'images/render_heart.png',
        alt: 'heart',
      },
      {
        title: 'Myau Myau Chai',
        details: 'Ivan chai ion cat-shaped box',
        descr: 'Fermented and packed Ivan-chai for the most yanger tealovers',
        price: '500 rub',
        shot: 'images/shot_cat.jpg',
        render: 'images/render_cat.png',
        alt: 'cat',
      },
      {
        title: 'Ivan-chai flower',
        details: 'Elite quailty Ivan-chai mad from kipreys leaves',
        descr: 'Super taste. Made explicity from kipreys flowers during active blooming. Fermented, packaged',
        price: '2000 rub',
        shot: 'images/shot_hex.jpg',
        render: 'images/render_hex.png',
        alt: 'hex',
      },
      {
        title: 'Mint Ivan-chai',
        details: 'Ivan-chai with mint leaves addition',
        descr: 'Ivan-chai, fermented, packaged, with original, natural mint leaves addition',
        price: '550 rub',
        shot: 'images/shot_green_penta.jpg',
        render: 'images/render_green_penta.png',
        alt: 'greenpenta',
      },
      {
        title: 'Vitamin Ivan-chai',
        details: 'Ivan-chai with calendua addition',
        descr: 'Fermented kiprey leaves with calendula flowers. Packaged.',
        price: '550 rub',
        shot: 'images/shot_mag_penta.jpg',
        render: 'images/render_mag_penta.png',
        alt: 'magentapenta',
      },
      {
        title: 'Ivan chai Healthy',
        details: 'Ivan chai with healing grass mix',
        descr: 'Ivan - chai with lime, clover, black currant additions',
        price: '600 rub',
        shot: 'images/shot_church.jpg',
        render: 'images/render_church.png',
        alt: 'church',
      },
      {
        title: 'Ivan-chai with love',
        details: 'Amazing tasy ivan-chai in warm, heart-shaped box',
        descr: 'Ivan chai in the souvenir heart-shaped box. Make an excellent gift for you or your loved one: an excellent gift in shine box',
        price: '700 rub',
        shot: 'images/shot_heart_red.jpg',
        render: 'images/render_heart_red.png',
        alt: 'heartred',
      },
      {
        title: 'Ivan-chai with lime',
        details: 'Ivan chai with lime flowers addition',
        descr: 'Contains fermenter kiprey leaves and lime flowers',
        price: '550 rub',
        shot: 'images/shot_yel_penta.jpg',
        render: 'images/render_yel_penta.png',
        alt: 'yelpenta',
      },
      {
        title: 'Friendly bear Ivan-chai',
        details: 'Strong and delicious Ivan chain in souvenir package',
        descr: 'Very strong taste, excellent fermentation and dried with high temperature',
        price: '600 rub',
        shot: 'images/shot_bear.jpg',
        render: 'images/render_bear.png',
        alt: 'bear',
      },
      {
        title: 'Ivan-chai for celebration',
        details: 'Ivan chai in birthday souvenir box',
        descr: 'Fermented, packaged, Ivan chai, in funny box with birthday message, text can be designed on your personal wish',
        price: '1000 rub',
        shot: 'images/shot_hb_penta.jpg',
        render: 'images/render_hb_penta.png',
        alt: 'hbpenta',
      },
    ];
    this.m_prodsRus = [
      {
        title: 'Сказочный чай',
        details: 'Черный иван-чай в упаковке сказочный домик',
        descr: 'Иван-чай, изготовленный из листов кипрея, ферментированный, пакетированный, в сувенирной упаковке домик',
        price: '500 руб',
        shot: 'images/shot_hut.jpg',
        render: 'images/render_hut.png',
        alt: 'hut',
      },
      {
        title: 'Чай с любовью',
        details: 'Черный иван-чай в очень милой упаковке в виде сердца, сделано с любовью',
        descr: 'Подарите себе или своим близким и любимым восхитительный пакетированный Иван-чай в яркой сувенирной упаковке',
        price: '700 руб',
        shot: 'images/shot_heart.jpg',
        render: 'images/render_heart.png',
        alt: 'heart',
      },
      {
        title: 'Чай кис кис',
        details: 'Иван-чай в упакове "котенок',
        descr: 'Для самых маленьких любителей чая, Иван-чай, ферментированный, пакетированный, в сувенирной упаковке "Котенок"',
        price: '500 руб',
        shot: 'images/shot_cat.jpg',
        render: 'images/render_cat.png',
        alt: 'cat',
      },
      {
        title: 'Иван-чай цветочный',
        details: 'Элитный Иван-чай из цветков Кипрея',
        descr: 'Изысканный тонкий вкус. Изготавливается исключительно только из цветов Кипрея, собранных в период цветения. Ферментирован, пакетирован',
        price: '2000 руб',
        shot: 'images/shot_hex.jpg',
        render: 'images/render_hex.png',
        alt: 'hex',
      },
      {
        title: 'Иван-чай мятный',
        details: 'Иван-чай с добавлением мяты',
        descr: 'Иван-чай, изготовленный из листов кипрея, ферментированный, пакетированный, с добавлением листа мяты',
        price: '550 руб',
        shot: 'images/shot_green_penta.jpg',
        render: 'images/render_green_penta.png',
        alt: 'greenpenta',
      },
      {
        title: 'Иван-чай витаминный',
        details: 'Иван-чай с добавлением календулы',
        descr: 'Содержит ферментированный лист кипрея, цветы календулы. Пакетирован.',
        price: '550 руб',
        shot: 'images/shot_mag_penta.jpg',
        render: 'images/render_mag_penta.png',
        alt: 'magentapenta',
      },
      {
        title: 'Иван-чай "залог здоровья"',
        details: 'Иван-чай со сбором лекарственных трав',
        descr: 'Иван-чай, изготовленный из листов кипрея, ферментированный, пакетированный с добавлением цветов липы, клевера, листа смородины',
        price: '600 руб',
        shot: 'images/shot_church.jpg',
        render: 'images/render_church.png',
        alt: 'church',
      },
      {
        title: 'Иван-чай с любовью',
        details: 'Иван-чай в сердечно теплой упаковке',
        descr: 'Иван-чай в сувенирной упаковке в виде сердца. Подарите себе или своим близким и любимым восхитительный пакетированный Иван-чай в яркой сувенирной упаковке',
        price: '700 руб',
        shot: 'images/shot_heart_red.jpg',
        render: 'images/render_heart_red.png',
        alt: 'heartred',
      },
      {
        title: 'Иван-чай липовый',
        details: 'Иван-чай с добавлением цветов липы',
        descr: 'Содержит ферментированный лист кипрея, цветы липы',
        price: '550 руб',
        shot: 'images/shot_yel_penta.jpg',
        render: 'images/render_yel_penta.png',
        alt: 'yelpenta',
      },
      {
        title: 'Иван-чай добрый мишка',
        details: 'Крепкий и терпкий Иван-чай в сувенирной поздравительной упаковке',
        descr: 'Черный Иван-чай, ферментированный, пакетированный, с крепким, терпким вкусом',
        price: '600 руб',
        shot: 'images/shot_bear.jpg',
        render: 'images/render_bear.png',
        alt: 'bear',
      },
      {
        title: 'Иван-чай поздравительный',
        details: 'Иван-чай в сувенирной поздравительной упаковке',
        descr: 'Черный Иван-чай, ферментированный, пакетированный, в оригинальной красочной сувенирной упаковке с  поздравительной надписью сделанной по Вашему индивидуальному заказу',
        price: '1000 руб',
        shot: 'images/shot_hb_penta.jpg',
        render: 'images/render_hb_penta.png',
        alt: 'hbpenta',
      },
    ];
  }
  render() {
    const langIndex = this.props.lang;
    const arrProds = (langIndex === LANG_ENG) ? this.m_prodsEng : this.m_prodsRus;

    // width="375px"
    // height="375px"
    const jsxCar = <div> <Carousel>
      {arrProds.map((d, i) => {
        const strTitle = d.title;
        const strDetails = d.details;
        const strDescr = d.descr;
        const strPrice = d.price;
        const strShot = d.shot;
        const strRender = d.render;
        const strAlt = d.alt;
        const strKey = `id_${i}`;
        return <Carousel.Item key={strKey}  >
          <Carousel.Caption>
            <h3 className="carousel-text-title">
              {strTitle}
            </h3>
            <p>
              {strDetails}
            </p>
            <div className="image-rounder">
              <img
                className="d-block w-100"
                src={strRender}
                alt={strAlt}
              />
            </div>
            <p>
              {strDescr}
            </p>
            <p className="carousel-text-price">
              {strPrice}
            </p>
          </Carousel.Caption>
          <img
            src={strShot}
            alt={strAlt}
          />
        </Carousel.Item>;
      })}
    </Carousel>      
    <br />
    </div>;
    return jsxCar;
  }

}

export default UiCarouselProd;