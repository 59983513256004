
// ********************************************************
// Imports
// ********************************************************


import React from 'react';
import UiLogoAni from './UiLogoAni';
import { LANG_ENG } from '../engine/Lang';

import { Modal, Row, Col } from 'react-bootstrap';

// ********************************************************
// Class
// ********************************************************



/**
 * Class UiAbout some text later...
 */
export default class UiAbout extends React.Component {
  render() {

    const langIndex = this.props.lang;
    const strTitle = (langIndex === LANG_ENG ) ? 'Luga Chai' : 'Луга чай';

    const stateVis = this.props.stateVis;
    const onHideFunc = this.props.onHide;
    this.m_hideFunc = onHideFunc;

    const strAbout = 
    <Modal show={stateVis} onHide={onHideFunc}  size="lg" centered="true" >

      <Modal.Header centered="true">
        {strTitle}
      </Modal.Header>

      <Modal.Body>

        <Row className="justify-content-md-center">
          <Col xl="auto">
            <UiLogoAni />
          </Col>
        </Row>

      </Modal.Body>

    </Modal>;

    return strAbout;
  }
}

