// ********************************************************
// Imports
// ********************************************************

import React, { Component } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';

import { LANG_ENG, LANG_RUS }  from '../engine/Lang';

// ********************************************************
// Class
// ********************************************************

class UiLang extends Component {
  constructor(props) {
    super(props);

    this.onLang = null;
    this.onEng = this.onEng.bind(this);
    this.onRus = this.onRus.bind(this);
  } // constr
  onEng() {
    // console.log('onEng');
    this.onLang(LANG_ENG);
  }
  onRus() {
    // console.log('onRus');
    this.onLang(LANG_RUS);
  }
  // render
  render() {
    const langIndex = this.props.lang;
    this.onLang = this.props.onLang;

    const styleEng = (langIndex === LANG_ENG) ? 'success' : 'secondary';
    const styleRus = (langIndex === LANG_RUS) ? 'success' : 'secondary';

    const jsx = <Form inline>
      <Row>
        <Col>
          <Button variant={styleEng} onClick={this.onEng} >
            Eng
          </Button>
        </Col>
        <Col>
          <Button variant={styleRus} onClick={this.onRus} >
            Рус
          </Button>
        </Col>
      </Row>
    </Form>;
    return jsx;
  } // render
} // class
export default UiLang;