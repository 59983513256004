// ********************************************************
// Imports
// ********************************************************

import React, { Component } from 'react';

import UiNavBar from './ui/UiNavBar';

// ********************************************************
// Class
// ********************************************************

class App extends Component {
  render() {
    const jsxApp = <UiNavBar />;
    return jsxApp;
  }
}

export default App;
