// **********************************************
// Imports
// **********************************************

import React  from 'react';
import { Image, ListGroup } from 'react-bootstrap';
import { LANG_ENG }  from '../engine/Lang';

import './UiStyles.css';

// **********************************************
// Class
// **********************************************

class UiOurProduction extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.m_arrWhyBestRus = [
      {
        text: 'Наш чай изготовлен по старинному русскому рецепту. Мы придерживаемся старой, проверенной веками технологии',
        icon: 'images/icon_history.png',
      },
      {
        text: 'Наш чай изготовлен полностью вручную. ВСЕ этапы изготовления продукта выполняются нами РУКАМИ: от сбора листьев/цветков до создания коробки',
        icon: 'images/icon_handmade.png',
      },
      {
        text: 'Мы одни из первых в северо-западном регионе России, кто производит пакетированный Иван-чай',
        icon: 'images/icon_first.png',
      },
      {
        text: 'Мы собираем иван-чай только в удаленных, труднодоступных районах Лениниградской области. Это ислючает грязь и вредные примеси',
        icon: 'images/icon_forest.png',
      },
      {
        text: 'Наши подарочные коробки уникальны не только в России, но и в мире',
        icon: 'images/icon_uniq_box.png',
      },
      {
        text: 'Наша продукция может быть заказана индивидуально. Мы можем изменить надписи для вас, создать уникальную форму коробки и так далее',
        icon: 'images/icon_indi_order.png',
      },
      {
        text: 'Никакого железа. Мы не используем мясорубки в процессе ферментации. Это позволяет сохранять натуральные свойства продукта без изменений',
        icon: 'images/icon_no_grinder.png',
      },
      {
        text: 'Никаких противней в процессе просушки. Мы сушим чай в электрической сушилке без высокой температуры. Это позволяет избавиться от эффекта "прожаренности" и терпкого вкуса',
        icon: 'images/icon_no_plate.png',
      },
      {
        text: 'Наш чай - длинно-листовой. Это подтверждает тот факт, что весь процесс на нашем производстве - ручной. Мы не делаем гранулы, мы не используем мясорубки',
        icon: 'images/icon_long_leaf.png',
      },
      {
        text: 'Каждый пакетик нашего чая не содержит металлических частей, опускаемых в чашку',
        icon: 'images/icon_no_clip.png',
      },
      {
        text: 'Каждый пакетик нашего чая имеет оригинальный держатель - бабочку',
        icon: 'images/icon_butterfly.png',
      },
    ];
    this.m_arrWhyBestEng = [
      {
        text: 'Our tea is made according to the old Russian recipe. We adhere to the old, proven technology for centuries',
        icon: 'images/icon_history.png',
      },
      {
        text: 'Our tea is made entirely by hand. ALL stages of product manufacturing are performed by us with HANDS: from collecting leaves / flowers to creating a box',
        icon: 'images/icon_handmade.png',
      },
      {
        text: 'We are one of the first in the north-western region of Russia to produce packaged Ivan-tea',
        icon: 'images/icon_first.png',
      },
      {
        text: 'We collect willow tea only in remote, hard-to-reach areas of the Leningrad region. This excludes dirt and harmful impurities',
        icon: 'images/icon_forest.png',
      },
      {
        text: 'Our gift boxes are unique not only in Russia, but also in the world',
        icon: 'images/icon_uniq_box.png',
      },
      {
        text: 'Our products can be ordered individually. We can change the labels for you, create a unique box shape and so on',
        icon: 'images/icon_indi_order.png',
      },
      {
        text: 'No iron. We do not use meat grinders in the fermentation process. This allows you to keep the natural properties of the product without changes',
        icon: 'images/icon_no_grinder.png',
      },
      {
        text: 'No baking/heat plates during the drying process. We dry the tea in an electric dryer without heat. This allows you to get rid of the effect of "roast" and tart taste',
        icon: 'images/icon_no_plate.png',
      },
      {
        text: 'Our tea is long-leaf. This confirms the fact that the whole process in our production is manual. We do not make pellets, we do not use meat grinders',
        icon: 'images/icon_long_leaf.png',
      },
      {
        text: 'Each bag of our tea does not contain any metal parts to be dropped into the cup',
        icon: 'images/icon_no_clip.png',
      },
      {
        text: 'Each bag of our tea has an original holder - a butterfly',
        icon: 'images/icon_butterfly.png',
      },
    ];
  } // end constr
  render() {
    const langIndex = this.props.lang;
    const strProdTitle = (langIndex === LANG_ENG) ? 'About our products' : 'О нашей продукции';
    const strWhy = (langIndex === LANG_ENG) ? 'Why our Ivan chai is the best' : 'Почему наш Иван-чай лучший';
    const arrWhy = (langIndex === LANG_ENG) ? this.m_arrWhyBestEng : this.m_arrWhyBestRus;


    const jsxRet = <div>
      <div className="lugachai-head-div" >
        <h1 className="lugachai-head-text">
          {strProdTitle}
        </h1>
      </div>
      <br />
      <div className="text-center">
        <Image src="images/tea_in_circle_metal_box.jpg" width="320px" fluid />        
        <p className="our-prod-why">
          {strWhy}
        </p>
      </div>
      <ListGroup>
        {arrWhy.map( (d, i) => {
          const strText = d.text;
          const strIcon = d.icon;
          const strKey = `id_${i}`;
          return <ListGroup.Item key={strKey} variant="success" >
            <img src={strIcon} alt="alt"/>
            {strText}
          </ListGroup.Item>
        })}
      </ListGroup>
      <br />        
    </div>;
    return jsxRet;
  } // render
}

export default UiOurProduction;