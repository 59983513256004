// **********************************************
// Imports
// **********************************************

import React  from 'react';

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import { LANG_ENG }  from '../engine/Lang';

import './UiStyles.css';


// **********************************************
// Class
// **********************************************

class UiRawTea extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.m_arrPhotos = [
      {
        original: "images/raw_black_terpkiy_Fotor.jpg",
        thumbnail: "images/raw_black_terpkiy_100x67.jpg",
        description: "Black strong tea / Черный терпкий чай",
      }, {
        original: "images/raw_black_mild_Fotor.jpg",
        thumbnail: "images/raw_black_mild_100x67.jpg",
        description: "Black soft tea / Черный мягкий чай",
      }, {
        original: "images/raw_black_calendula_Fotor.jpg",
        thumbnail: "images/raw_black_calendula_100x67.jpg",
        description: "Black tea with calendula / Черный чай с календулой",
      }, {
        original: "images/raw_black_chernika_zemlyanika_Fotor.jpg",
        thumbnail: "images/raw_black_chernika_zemlyanika_100x67.jpg",
        description: "Black tea with blueberries and strawberries / Черный чай с черникой и земляникой",
      }, {
        original: "images/raw_black_currant_flowers_Fotor.jpg",
        thumbnail: "images/raw_black_currant_flowers_100x67.jpg",
        description: "Black tea with black currant / Черный чай с черной смородиной",
      }, {
        original: "images/raw_green_Fotor.jpg",
        thumbnail: "images/raw_green_100x67.jpg",
        description: "Green tea / Зеленый чай",
      }, {
        original: "images/raw_lipa_Fotor.jpg",
        thumbnail: "images/raw_lipa_100x67.jpg",
        description: "Black tea with linden flowers / Черный чай с цветами липы",
      }];
  } // end constr
  render() {
    const langIndex = this.props.lang;
    const strRawTitle = (langIndex === LANG_ENG) ? 'Our tea blends' : 'Наши чайные смеси';

    const jsxRawTea = <div>
      <div className="lugachai-head-div" >
        <h1 className="lugachai-head-text">
          {strRawTitle}
        </h1>
      </div>
      <br />
      <div>
        <ImageGallery 
          items={this.m_arrPhotos} 
          sumbnailPosition={'top'}
          showBullets={true}
          showIndex={true}
          autoPlay={true}
          showFullscreenButton={false} />
      </div>
    </div>;
    return jsxRawTea;

  } // render
}

export default UiRawTea;