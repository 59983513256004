// ********************************************************
// Imports
// ********************************************************

import React from 'react';

import AnimTools from '../anim/AnimTools';
import AnimHand from '../anim/AnimHand';
import AnimLuga from '../anim/AnimLuga';

// ********************************************************
// Const
// ********************************************************

const Phase = {
  PHASE_GRASS_A: 0,
  PHASE_GRASS_B: 1,
  PHASE_GRASS_C: 2,
  PHASE_MOUNTAIN: 3,
  PHASE_SUN: 4,
  PHASE_LOW_GRASS: 5,
  PHASE_LEAF_UP: 6,
  PHASE_SYSTEM_GROW: 7,
  PHASE_SYSTEM_MAGENTIZE: 8,
  PHASE_NATURE_REMOVE: 9,
  PHASE_LOGO_TEXT_APPEAR: 10,
  PHASE_LOGO_TEXT_STAY_STATIC: 11,
  PHASE_REMOVE_ALL: 12,
};

const W_REF = 725.0;
const H_REF = 472.0;

const STR_LUGA_CHAI_ENG = 'TEA';
const STR_LUGA_CHAI_RUS = 'ЧАЙ';
const STR_WEB_LUGA_CHAI = 'www.lugachai.ru';

const URL_TO_GO = 'https://www.lugachai.ru';

// ********************************************************
// Class
// ********************************************************

/**
 * Class UiLogoAni some text later...
 */
class UiLogoAni extends React.Component {
  /**
   * @param {object} props - props from up level object
   */
  constructor(props) {
    super(props);

    this.animate = this.animate.bind(this);
    this.start = this.start.bind(this);
    this.renderWithCtx = this.renderWithCtx.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);

    this.m_mount = null;
    this.m_frameId = null;

    this.m_startTime = -1;
    this.m_phase = Phase.PHASE_GRASS_A;

    this.m_grassLines = [];
  }
  onMouseDown() {
    if (this.m_phase >= Phase.PHASE_LOGO_TEXT_APPEAR) {
      // console.log('go to page by click');
      this.stop();
      window.location.assign(URL_TO_GO);
    }
  }
  animate() {
    this.renderWithCtx();
    this.m_frameId = window.requestAnimationFrame(this.animate);
  }
  start() {
    if (this.m_frameId === null) {
      this.m_frameId = requestAnimationFrame(this.animate);
      //this.m_started = true;
      //this.m_startTime = Date.now();      
    }
  }
  stop() {
    cancelAnimationFrame(this.m_frameId);
    this.m_frameId = null;
    this.m_startTime = -1;
    // this.m_started = false;
  }
  componentDidMount() {
    console.log('UiLogoAni. start animations');
    this.start();
  }
  componentWillUnmount() {
    console.log('UiLogoAni. stop animations');
    this.stop();
  }
  /**
   * Main component render func callback
   */
  render() {
    const strStyle = {
      border: '0px solid black'
    };

    const wWindow = window.innerWidth;
    // console.log(`wWindow = ${wWindow}`);
    let w = 0;
    if (wWindow > 700) {
      w = 600;
    } else {
      w = Math.floor(wWindow * 0.9);
    }
    const ASPECT = 480.0 / 600.0;
    const h = Math.floor(w * ASPECT);
    // console.log(`w*h = ${w} * ${h}`);
    const strW = w.toString() + 'px';
    const strH = h.toString() + 'px';

    const jsxAni = 
      <div>
        <canvas className="img-responsive" style={strStyle}
          ref={ (mount) => {this.m_mount = mount} } min-width={strW} width={strW} height={strH} onMouseDown={this.onMouseDown} />
      </div>;
    return jsxAni;
  } // end render
  /**
   * Render front valley
   * 
   * @param {object} ctx - render context
   * @param {number} w - width
   * @param {number} h - height screeb
   * @param {number} lightGlobal - global lighting in [0..1]
   */
  renderPhaseGrassA(ctx, w, h, lightGlobal) {
    ctx.beginPath();

    const phase = this.m_phase;
    const timeRatio = this.m_timePhase;
    let yAdd = 0;
    if (phase === Phase.PHASE_GRASS_A) {
      yAdd = (472 - 296) * (1.0 - timeRatio);
    }
    let alpha = 1.0;
    if (phase === Phase.PHASE_NATURE_REMOVE) {
      alpha = 1.0 - timeRatio;
    }
    if (phase > Phase.PHASE_NATURE_REMOVE) {
      return;
    }
    const y0 = yAdd + h * 302 / H_REF;
    const y1 = yAdd + h * 283 / H_REF;
    const y2 = yAdd + h * 390 / H_REF;
    const y3 = yAdd + h * 364 / H_REF;

    const x0 = 0;
    const x1 = w * 0.33;
    const x2 = w * 0.66;
    const x3 = w  - 1;

    ctx.moveTo(x0, y0);
    ctx.bezierCurveTo(x1, y1, x2, y2, x3, y3);
    ctx.lineTo(x3, yAdd + h - 1);
    ctx.lineTo(x0, yAdd + h - 1);
    ctx.lineTo(x0, y0);

    // ctx.fillStyle = 'rgb(50, 250, 50)';
    const gradBack = ctx.createLinearGradient(x0, y0, x0, yAdd + h - 1);

    const ADDL = 80;
    let rS = 250 - ADDL + ADDL * lightGlobal;
    let gS = 220 - ADDL + ADDL * lightGlobal;
    let bS = 90 - ADDL + ADDL * lightGlobal;
    let rE = 100 - ADDL + ADDL * lightGlobal;
    let gE = 160 - ADDL + ADDL * lightGlobal;
    let bE = 45 - ADDL + ADDL * lightGlobal;
    bS = (bS >= 0) ? bS : 0;
    rE = (rE >= 0) ? rE : 0;
    gE = (gE >= 0) ? gE : 0;

    const strBeg = 'rgba(' + rS.toString() + ', ' + gS.toString() + ', ' + bS.toString() + ',' + alpha.toFixed(2) + ')';
    const strEnd = 'rgba(' + rE.toString() + ', ' + gE.toString() + ', ' + bE.toString() + ',' + alpha.toFixed(2) + ')';
    gradBack.addColorStop(0.0, strBeg);
    gradBack.addColorStop(1.0, strEnd);
    ctx.fillStyle = gradBack;

    ctx.fill();
  }
  /**
   * Render 2nd valley
   * 
   * @param {object} ctx - render context
   * @param {number} w - width
   * @param {number} h - height screeb
   * @param {number} lightGlobal - lightness in [0..1]
   */
  renderPhaseGrassB(ctx, w, h, lightGlobal) {
    const phase = this.m_phase;
    const timeRatio = this.m_timePhase;

    if (phase < Phase.PHASE_GRASS_B) {
      return;
    }
    ctx.beginPath();

    let yAdd = 0;
    if (phase === Phase.PHASE_GRASS_B) {
      yAdd = (472 - 251) * (1.0 - timeRatio);
    }
    let alpha = 1.0;
    if (phase === Phase.PHASE_NATURE_REMOVE) {
      alpha = 1.0 - timeRatio;
    }
    if (phase > Phase.PHASE_NATURE_REMOVE) {
      return;
    }

    const y0 = yAdd + h * 322 / H_REF;
    const y1 = yAdd + h * 334 / H_REF;
    const y2 = yAdd + h * 232 / H_REF;
    const y3 = yAdd + h * 262 / H_REF;

    const x0 = 0;
    const x1 = w * 0.33;
    const x2 = w * 0.66;
    const x3 = w  - 1;

    ctx.moveTo(x0, y0);
    ctx.bezierCurveTo(x1, y1, x2, y2, x3, y3);
    ctx.lineTo(x3, yAdd + h - 1);
    ctx.lineTo(x0, yAdd + h - 1);
    ctx.lineTo(x0, y0);

    const gradBack = ctx.createLinearGradient(x0, y0, x0, y3);
    const ADDL = 80;
    let rS = 90 - ADDL + ADDL * lightGlobal;
    let gS = 180 - ADDL + ADDL * lightGlobal;
    let bS = 80 - ADDL + ADDL * lightGlobal;
    let rE = 180 - ADDL + ADDL * lightGlobal;
    let gE = 230 - ADDL + ADDL * lightGlobal;
    let bE = 120 - ADDL + ADDL * lightGlobal;
    rS = (rS >= 0) ? rS : 0;
    bS = (bS >= 0) ? bS : 0;
    gE = (gE >= 0) ? gE : 0;

    const strBeg = 'rgba(' + rS.toString() + ', ' + gS.toString() + ', ' + bS.toString() + ',' + alpha.toFixed(2) + ')';
    const strEnd = 'rgba(' + rE.toString() + ', ' + gE.toString() + ', ' + bE.toString() + ',' + alpha.toFixed(2) + ')';
    gradBack.addColorStop(0.0, strBeg);
    gradBack.addColorStop(1.0, strEnd);
    ctx.fillStyle = gradBack;

    ctx.fill();
  }
  /**
   * Render 3rd valley
   * 
   * @param {object} ctx - render context
   * @param {number} w - width
   * @param {number} h - height screeb
   * @param {number} lightGlobal - lighting in [0..1]
   */
  renderPhaseGrassC(ctx, w, h, lightGlobal) {
    const phase = this.m_phase;
    const timeRatio = this.m_timePhase;
    if (phase < Phase.PHASE_GRASS_C) {
      return;
    }
    ctx.beginPath();

    let yAdd = 0;
    if (phase === Phase.PHASE_GRASS_C) {
      yAdd = (472 - 206) * (1.0 - timeRatio);
    }
    let alpha = 1.0;
    if (phase === Phase.PHASE_NATURE_REMOVE) {
      alpha = 1.0 - timeRatio;
    }
    if (phase > Phase.PHASE_NATURE_REMOVE) {
      return;
    }

    const y0 = yAdd + h * 249 / H_REF;
    const y1 = yAdd + h * 140 / H_REF;
    const y2 = yAdd + h * 340 / H_REF;
    const y3 = yAdd + h * 310 / H_REF;

    const x0 = 0;
    const x1 = w * 0.33;
    const x2 = w * 0.66;
    const x3 = w  - 1;

    ctx.moveTo(x0, y0);
    ctx.bezierCurveTo(x1, y1, x2, y2, x3, y3);
    ctx.lineTo(x3, yAdd + h - 1);
    ctx.lineTo(x0, yAdd + h - 1);
    ctx.lineTo(x0, y0);

    const gradBack = ctx.createLinearGradient(x0, y0, x0, y3);

    const ADDL = 80;
    let rS = 170 - ADDL + ADDL * lightGlobal;
    let gS = 220 - ADDL + ADDL * lightGlobal;
    let bS = 200 - ADDL + ADDL * lightGlobal;
    let rE = 140 - ADDL + ADDL * lightGlobal;
    let gE = 180 - ADDL + ADDL * lightGlobal;
    let bE = 143 - ADDL + ADDL * lightGlobal;
    rS = (rS >= 0) ? rS : 0;
    bS = (bS >= 0) ? bS : 0;
    gE = (gE >= 0) ? gE : 0;

    const strBeg = 'rgba(' + rS.toString() + ', ' + gS.toString() + ', ' + bS.toString() + ',' + alpha.toFixed(2) + ')';
    const strEnd = 'rgba(' + rE.toString() + ', ' + gE.toString() + ', ' + bE.toString() + ',' + alpha.toFixed(2) + ')';
    gradBack.addColorStop(0.0, strBeg);
    gradBack.addColorStop(1.0, strEnd);
    ctx.fillStyle = gradBack;

    ctx.fill();
  }
  /**
   * Render mountain
   * 
   * @param {object} ctx - render context
   * @param {number} w - width
   * @param {number} h - height screeb
   * @param {number} lightGlobal - lighting in [0..1]
   */
  renderPhaseMountain(ctx, w, h, lightGlobal) {
    const phase = this.m_phase;
    const timeRatio = this.m_timePhase;
    if (phase < Phase.PHASE_MOUNTAIN) {
      return;
    }
    ctx.beginPath();

    let yAdd = 0;
    if (phase === Phase.PHASE_MOUNTAIN) {
      yAdd = (472 - 111) * (1.0 - timeRatio);
    }
    let alpha = 1.0;
    if (phase === Phase.PHASE_NATURE_REMOVE) {
      alpha = 1.0 - timeRatio;
    }
    if (phase > Phase.PHASE_NATURE_REMOVE) {
      return;
    }

    const x0 = 148 * w / W_REF;
    const y0 = 251 * h / H_REF + yAdd;

    const x1 = 206 * w / W_REF;
    const y1 = 131 * h / H_REF + yAdd;
    const x2 = 223 * w / W_REF;
    const y2 = 124 * h / H_REF + yAdd;
    const x3 = 240 * w / W_REF;
    const y3 = 133 * h / H_REF + yAdd;
    const x4 = 293 * w / W_REF;
    const y4 = 250 * h / H_REF + yAdd;

    ctx.moveTo(x0, y0);
    ctx.quadraticCurveTo(x1, y1, x2, y2);
    ctx.quadraticCurveTo(x3, y3, x4, y4);
    ctx.lineTo(x0, y0);

    // ctx.fillStyle = 'rgb(50, 250, 50)';
    const gradBack = ctx.createLinearGradient(x0, y2, x0, y4);
    const ADDL = 120;
    let rS = 240 - ADDL + ADDL * lightGlobal;
    let gS = 240 - ADDL + ADDL * lightGlobal;
    let bS = 255 - ADDL + ADDL * lightGlobal;
    let rE = 100 - ADDL + ADDL * lightGlobal;
    let gE = 160 - ADDL + ADDL * lightGlobal;
    let bE = 160 - ADDL + ADDL * lightGlobal;
    rE = (rE >= 0) ? rE : 0;
    gE = (gE >= 0) ? gE : 0;
    bE = (bE >= 0) ? bE : 0;

    const strBeg = 'rgba(' + rS.toString() + ', ' + gS.toString() + ', ' + bS.toString() + ',' + alpha.toFixed(2) + ')';
    const strEnd = 'rgba(' + rE.toString() + ', ' + gE.toString() + ', ' + bE.toString() + ',' + alpha.toFixed(2) + ')';
    gradBack.addColorStop(0.0, strBeg);
    gradBack.addColorStop(1.0, strEnd);

    ctx.fillStyle = gradBack;

    ctx.fill();
  }
  /**
   * Render sun
   * 
   * @param {object} ctx - render context
   * @param {number} w - width
   * @param {number} h - height screeb
   */
  renderPhaseSun(ctx, w, h) {
    const phase = this.m_phase;
    const timeRatio = this.m_timePhase;
    if (phase < Phase.PHASE_SUN) {
      return;
    }

    let yAdd = 0;
    let tr = 1.0;
    if (phase === Phase.PHASE_SUN) {
      tr = timeRatio;
      yAdd = (250 - 130) * (1.0 - timeRatio);
    }
    let alpha = 1.0;
    if (phase === Phase.PHASE_NATURE_REMOVE) {
      alpha = 1.0 - timeRatio;
    }
    if (phase > Phase.PHASE_NATURE_REMOVE) {
      return;
    }

    const xc = 520 * w / W_REF;
    const yc = 250 * h / H_REF + yAdd;
    const rad = 60 * w / W_REF;

    // draw sun rays
    const alp = 0.4 * tr * alpha;
    const strColor = 'rgba(240, 240, 240,' + alp.toFixed(2) + ')' ; 
    // console.log(`strColor = ${strColor}`);
    ctx.fillStyle = strColor;
    const NUM_RAYS = 6;
    const ANG_RANGE = (180.0 / NUM_RAYS) / 8.0;
    const radRays = w * 1.4;
    for (let i = 0; i <= NUM_RAYS; i++) {
      const angle = i * 180.0 / NUM_RAYS;
      const angS = 180 + angle - ANG_RANGE;
      const angE = 180 + angle + ANG_RANGE;
      // console.log(`ang = ${angS} .. ${angE}`);
      ctx.beginPath();
      ctx.moveTo(xc, yc);
      ctx.arc(xc, yc, radRays, angS * 3.1415 / 180.0, angE * 3.1415 / 180.0, false);
      ctx.lineTo(xc, yc);
      ctx.fill();
    }

    // draw sun circle
    const gradBack = ctx.createLinearGradient(xc, yc - rad, xc, yc + rad);

    const colYellLight = {
      r: 240, g: 240, b: 100, a: alpha
    };
    const colYellDark = {
      r: 240, g: 200, b: 30, a: alpha
    };
    const strColS = AnimTools.getRgbaColorString(colYellLight);
    const strColE = AnimTools.getRgbaColorString(colYellDark);
    // gradBack.addColorStop(0.0, 'rgb(240, 240, 100)');
    // gradBack.addColorStop(1.0, 'rgb(240, 200, 30)');
    gradBack.addColorStop(0.0, strColS);
    gradBack.addColorStop(1.0, strColE);
    ctx.fillStyle = gradBack;

    ctx.beginPath();
    ctx.arc(xc, yc, rad, 0.0, 3.1415926 * 2, false);
    ctx.fill();
  }
  renderPhaseLowGrass(ctx, w, h) {
    const phase = this.m_phase;
    const timeRatio = this.m_timePhase;
    if (phase < Phase.PHASE_LOW_GRASS) {
      return;
    }
    let yAdd = 0;
    if (phase === Phase.PHASE_LOW_GRASS) {
      yAdd = (468 - 340) * (1.0 - timeRatio);
    }
    let alpha = 1.0;
    if (phase === Phase.PHASE_NATURE_REMOVE) {
      alpha = 1.0 - timeRatio;
    }
    if (phase > Phase.PHASE_NATURE_REMOVE) {
      return;
    }

    let i;
    let numLines = this.m_grassLines.length;
    if (numLines === 0) {
      const N_LINES = 48;
      const XSTEP = w / (N_LINES - 16);
      // add lines
      for (i = 0; i < N_LINES; i++) {
        const xc = w * i / N_LINES;
        const H_MAX = (468 - 340) * h / H_REF;
        const hCur = H_MAX - (H_MAX * 0.3) * Math.random();
        const tCol = Math.random();
        const objLine = {
          x0: xc,
          y0: h - 1,
          x1: xc - (XSTEP * 0.5) + (XSTEP * Math.random()),
          y1: h - 1 - hCur *  0.33,
          x2: xc - (XSTEP * 0.5) + (XSTEP * Math.random()),
          y2: h - 1 - hCur *  0.66,
          x3: xc - (XSTEP * 0.5) + (XSTEP * Math.random()),
          y3: h - 1 - hCur,
          rCol: Math.floor(170 * (1.0 - tCol) + 100 * tCol),
          gCol: Math.floor(220 * (1.0 - tCol) + 160 * tCol),
          bCol: Math.floor(100 * (1.0 - tCol) + 50  * tCol),
          w: 0.2 * XSTEP + 0.8 * XSTEP * Math.random(),
        };
        this.m_grassLines.push(objLine);
      }

      numLines = this.m_grassLines.length;
    }
    // ctx.fillStyle = 'rgb(70, 160, 70)';
    // ctx.strokeStyle = 'rgb(70, 160, 70)';
    for (i = 0; i < numLines; i++) {
      const objLine = this.m_grassLines[i];
      const strCol = 'rgba(' + objLine.rCol.toString() + ', ' + objLine.gCol.toString() + ', ' +
        objLine.bCol.toString() + ', ' + alpha.toFixed(2) + ')';
      ctx.strokeStyle = strCol;
      ctx.fillStyle = strCol;
      ctx.beginPath();

      const w = objLine.w;
      ctx.moveTo(objLine.x0 - w * 0.5, objLine.y0 + yAdd);
      ctx.bezierCurveTo(objLine.x1 - w * 0.3, objLine.y1 + yAdd,
        objLine.x2 - w * 0.15, objLine.y2 + yAdd,
        objLine.x3, objLine.y3 + yAdd);
      ctx.bezierCurveTo(objLine.x2 + w * 0.15, objLine.y2 + yAdd,
        objLine.x1 + w * 0.3, objLine.y1 + yAdd,
        objLine.x0 + w * 0.5, objLine.y0 + yAdd);
      ctx.lineTo(objLine.x0 - w * 0.5, objLine.y0 + yAdd);
      ctx.fill();
    }
  }
  /**
   * 
   * @param {object} ctx - render context
   * @param {number} xc - leaf coord system start x
   * @param {number} yc  - leaf coord system start x
   * @param {number} scale - leaf scale from [0..1]
   * @param {number} angle - rotation
   */
  renderSingleLeaf(ctx, xc, yc, scale, angle, alpha) {
    const points = [
      524, 16,
      416, 100,
      182, -60,
      0, 70,
      170, 170,
      370, 170,
    ];
    const NUM_POINTS = Math.floor(points.length / 2);
    const ptsNorm = new Array(NUM_POINTS * 2);

    let i; let j;
    for (i = 0, j = 0; i < NUM_POINTS; i++, j += 2) {
      const xOld = points[j + 0];
      const yOld = points[j + 1];
      const x = (xOld - 0) / 524.0;
      const y = (yOld - 70) / 524.0;
      ptsNorm[j + 0] = x;
      ptsNorm[j + 1] = y;
    }
    const ca = Math.cos(angle);
    const sa = Math.sin(angle);
    const ptsTra = new Array(NUM_POINTS * 2);
    for (i = 0, j = 0; i < NUM_POINTS; i++, j += 2) {
      const xRot = ptsNorm[j + 0] * ca + ptsNorm[j + 1] * (-sa);
      const yRot = ptsNorm[j + 0] * sa + ptsNorm[j + 1] * ca;
      const x = xc + scale * xRot;
      const y = yc + scale * yRot;
      ptsTra[j + 0] = x;
      ptsTra[j + 1] = y;
    }

    const x0 = ptsTra[0];
    const y0 = ptsTra[1];
    const x3 = ptsTra[6];
    const y3 = ptsTra[7];
    // console.log(`grad: ${x0},${y0} -> ${x3},${y3}`);

    const colWhite = { r: 250, g: 250, b: 250, a: alpha };
    const colGreenLight = { r: 0, g: 250, b: 0, a: alpha };
    const colGreenDark = { r: 0, g: 180, b: 0, a: alpha };
    const colMagentaLight = { r: 250, g: 0, b: 250, a: alpha };
    const colMagentaDark = { r: 180, g: 0, b: 180, a: alpha };

    let colFillStart = { r: 0, g: 0, b: 0, a: alpha };
    let colFillEnd = { r: 0, g: 0, b: 0, a: alpha };
    let colStroke = { r: 0, g: 0, b: 0, a: alpha };

    const gradBack = ctx.createLinearGradient(x0, y0, x3, y3);
    if (this.m_phase < Phase.PHASE_SYSTEM_MAGENTIZE) {
      colFillStart = colWhite;
      colFillEnd = colGreenLight;
      colStroke = colGreenDark;
    } else if (this.m_phase === Phase.PHASE_SYSTEM_MAGENTIZE) {
      const t = this.m_timePhase;
      AnimTools.colorInterpolate(colFillStart, colWhite, colWhite, t);
      AnimTools.colorInterpolate(colFillEnd, colGreenLight, colMagentaLight, t);
      AnimTools.colorInterpolate(colStroke, colGreenDark, colMagentaDark, t);
    } else {
      colFillStart = colWhite;
      colFillEnd = colMagentaLight;
      colStroke = colMagentaDark;
    }
    const strColorStart = AnimTools.getRgbaColorString(colFillStart);
    const strColorStop = AnimTools.getRgbaColorString(colFillEnd);
    const strColorStroke = AnimTools.getRgbaColorString(colStroke);
    gradBack.addColorStop(0.0, strColorStart);
    gradBack.addColorStop(1.0, strColorStop);
    ctx.strokeStyle = strColorStroke;

    ctx.fillStyle = gradBack;
    ctx.lineWidth = 2.0;

    ctx.beginPath();
    ctx.moveTo(ptsTra[0], ptsTra[1]);
    ctx.bezierCurveTo(ptsTra[2], ptsTra[3],
      ptsTra[4], ptsTra[5],
      ptsTra[6], ptsTra[7]);
    ctx.bezierCurveTo(ptsTra[8], ptsTra[9],
      ptsTra[10], ptsTra[11],
      ptsTra[0], ptsTra[1]);
    ctx.fill();
    ctx.stroke();

    // test render center point
    // ctx.fillStyle = 'rgba(240, 0, 0, 0.4)';
    // ctx.beginPath();
    // ctx.arc(xc, yc, 12, 0.0, 3.1415926 * 2, false);
    // ctx.fill();
  }
  renderLeafSystem(ctx, xc, yc, hScale, angleMain, alpha) {
    const radRot = hScale * 0.1;
    const angleM2 = angleMain - 90 * 3.1515 / 180.0;
    const angleM1 = angleMain - 45 * 3.1515 / 180.0;
    const angleP1 = angleMain + 45 * 3.1515 / 180.0;
    const angleP2 = angleMain + 90 * 3.1515 / 180.0;

    const xM2 = xc + radRot * Math.cos(angleM2);
    const yM2 = yc + radRot * Math.sin(angleM2);
    const xM1 = xc + radRot * Math.cos(angleM1);
    const yM1 = yc + radRot * Math.sin(angleM1);
    const x0 = xc + radRot * Math.cos(angleMain);
    const y0 = yc + radRot * Math.sin(angleMain);
    const xP1 = xc + radRot * Math.cos(angleP1);
    const yP1 = yc + radRot * Math.sin(angleP1);
    const xP2 = xc + radRot * Math.cos(angleP2);
    const yP2 = yc + radRot * Math.sin(angleP2);

    // console.log(`renderLeafSystem. x0, y0 = ${x0}, ${y0}`);

    this.renderSingleLeaf(ctx, xM2, yM2, hScale, angleM2, alpha);
    this.renderSingleLeaf(ctx, xM1, yM1, hScale, angleM1, alpha);

    this.renderSingleLeaf(ctx, x0, y0, hScale, angleMain, alpha);

    this.renderSingleLeaf(ctx, xP1, yP1, hScale, angleP1, alpha);
    this.renderSingleLeaf(ctx, xP2, yP2, hScale, angleP2, alpha);
  }
  renderPhaseLeafUp(ctx, w, h) {
    const phase = this.m_phase;
    const timeRatio = this.m_timePhase;
    if (phase < Phase.PHASE_LEAF_UP) {
      return;
    }
    let t = 1.0;
    if (phase === Phase.PHASE_LEAF_UP) {
      t = timeRatio;
    }
    let alpha = 1.0;
    if (phase === Phase.PHASE_NATURE_REMOVE) {
      alpha = 1.0 - timeRatio;
    }
    if (phase > Phase.PHASE_NATURE_REMOVE) {
      return;
    }

    const v0 = {
      x: w * 0.5,
      y: h - 1
    };
    const v1 = {
      x: w * 0.5,
      y: h * 0.5 + h * 0.5 * 0.66
    };
    const v2 = {
      x: w * 0.5,
      y: h * 0.5 + h * 0.5 * 0.33
    };
    const v3 = {
      x: w * 0.5,
      y: h * 0.5
    };
    const v01 = {
      x: v1.x - v0.x,
      y: v1.y - v0.y
    };
    const v02 = {
      x: v2.x - v0.x,
      y: v2.y - v0.y
    };
    const ANG_BASE = 25.0 * 3.14 / 180.0;
    AnimTools.rotateVec(v01, +ANG_BASE);
    AnimTools.rotateVec(v02, -ANG_BASE);
    AnimTools.addVec(v1, v01, v0);
    AnimTools.addVec(v2, v02, v0);

    const vecEnd = { x: 0.0, y: 0.0 };
    AnimTools.bezierGetPoint(v0, v1, v2, v3, t, vecEnd);

    const lenCurve = AnimTools.distanceBetween(vecEnd, v0);
    const SEG_LEN_IN_PIX = 4;
    const numSteps = Math.floor(lenCurve / SEG_LEN_IN_PIX);

    const vecCur = { x: 0.0, y: 0.0 };
    ctx.strokeStyle = 'rgba(0, 180, 0, ' + alpha.toFixed(2) + ')';
    ctx.lineWidth = 4.0;
    ctx.beginPath();
    ctx.moveTo(v0.x, v0.y);
    for (let i = 1; i <= numSteps; i++) {
      const tCur = t * i / numSteps;
      AnimTools.bezierGetPoint(v0, v1, v2, v3, tCur, vecCur);
      ctx.lineTo(vecCur.x, vecCur.y);
    }
    ctx.stroke();

    // draw end point circle
    const radCircle = h * 0.04;
    ctx.fillStyle = 'rgb(0, 180, 0, ' + alpha.toFixed(2) + ')';
    ctx.beginPath();
    ctx.arc(vecCur.x, vecCur.y, radCircle, 0.0, 3.1415926 * 2, false);
    ctx.fill();


    /*
    // test render base points
    ctx.fillStyle = 'rgba(240, 0, 0, 0.4)';
    ctx.beginPath();
    ctx.arc(v0.x, v0.y, 12, 0.0, 3.1415926 * 2, false);
    ctx.fill();
    ctx.beginPath();
    ctx.arc(v1.x, v1.y, 12, 0.0, 3.1415926 * 2, false);
    ctx.fill();
    ctx.beginPath();
    ctx.arc(v2.x, v2.y, 12, 0.0, 3.1415926 * 2, false);
    ctx.fill();
    ctx.beginPath();
    ctx.arc(v3.x, v3.y, 12, 0.0, 3.1415926 * 2, false);
    ctx.fill();
    */
  }
  /**
   * 
   * @param {object} ctx - render context
   * @param {number} w - screen width
   * @param {number} h - screen height
   */
  renderPhaseSystemGrow(ctx, w, h) {
    const phase = this.m_phase;
    const timeRatio = this.m_timePhase;
    if (phase < Phase.PHASE_SYSTEM_GROW) {
      return;
    }
    let scale = 1.0;
    if (phase === Phase.PHASE_SYSTEM_GROW) {
      scale = timeRatio;
    }
    let alpha = 1.0;
    if (phase === Phase.PHASE_REMOVE_ALL) {
      alpha = 1.0 - timeRatio;
    }
    if (phase > Phase.PHASE_REMOVE_ALL) {
      return;
    }
    const xc = Math.floor(w * 0.5);
    const yc = Math.floor(h * 0.5);
    const hScale = scale * h * 0.4;

    const angleMain = -90.0 * 3.1415926 / 180.0;
    this.renderLeafSystem(ctx, xc, yc, hScale, angleMain, alpha);
  }
  /**
   * 
   * @param {object} ctx 
   * @param {number} w 
   * @param {number} h 
   */
  renderPhaseLogoTextAppear(ctx, w, h) {
    const phase = this.m_phase;
    const timeRatio = this.m_timePhase;
    if (phase < Phase.PHASE_LOGO_TEXT_APPEAR) {
      return;
    }

    let alpha = 1.0;
    if (phase === Phase.PHASE_REMOVE_ALL) {
      alpha = 1.0 - timeRatio;
    }
    if (phase > Phase.PHASE_REMOVE_ALL) {
      return;
    }

    let t = 1.0;
    if (phase === Phase.PHASE_LOGO_TEXT_APPEAR) {
      t = timeRatio;
    } else if (phase === Phase.PHASE_REMOVE_ALL) {
      t = alpha;
    }

    const strEng = STR_LUGA_CHAI_ENG;
    const strRus = STR_LUGA_CHAI_RUS;
    const strWebSite = STR_WEB_LUGA_CHAI;

    const xc = Math.floor(w * 0.5);
    let yc = Math.floor(h * 0.5);

    // curve logo
    const logoWidth = w * 0.3;
    const logoHeight = logoWidth * 73.0 / 200.0;
    yc += logoHeight * 0.9;
    AnimLuga.render(ctx, xc, yc, logoWidth, t);
    yc += logoHeight * 0.5;

    // text
    ctx.textAlign = 'center';
    ctx.textBaseline = 'top';
    
    const colWhite = { r: 250, g: 250, b: 250, a: t };
    const strCol = AnimTools.getRgbaColorString(colWhite);
    ctx.fillStyle = strCol;

    const FONT_SZ = 24;
    ctx.font = FONT_SZ.toString() + 'px Arial';

    yc += 0.3 * FONT_SZ;
    ctx.fillText(strEng, xc, yc);
    yc += 1.0 * FONT_SZ;
    ctx.fillText(strRus, xc, yc);

    ctx.textBaseline = 'bottom';
    const FONT_URL_SZ = 20;
    ctx.font = FONT_URL_SZ.toString() + 'px Times';
    yc = h - 1 - FONT_URL_SZ * 0.3;

    const colBlue = { r: 20, g: 20, b: 250, a: t };
    const strColBlue = AnimTools.getRgbaColorString(colBlue);
    ctx.fillStyle = strColBlue;

    ctx.fillText(strWebSite, xc, yc);
    const rcWeb = ctx.measureText(strWebSite);
    const wText2 = rcWeb.width / 2;
    const wHand = w * 0.08;
    const xStart = xc - wText2 - (w * 0.3);
    const xEnd = xc - wText2 - 4;
    const x = xStart * (1 - t) + xEnd * t;
    AnimHand.render(ctx, x, yc - FONT_URL_SZ * 0.5, wHand, t);

  }
  /**
   * Render scene on canvas
   */
  renderWithCtx() {
    const objCanvas = this.m_mount;
    if (objCanvas === null) {
      return;
    }
    const ctx = objCanvas.getContext('2d');
    const w = objCanvas.clientWidth;
    const h = objCanvas.clientHeight;
    if (w * h === 0) {
      return;
    }
    // console.log(`UiLogoAni. renderCtx. screen = ${w} * ${h}`);
    // clerar all screen

    const gx0 = 0;
    const gy0 = 0;
    const gx1 = 0;
    const gy1 = h * 0.5;
    const gradBack = ctx.createLinearGradient(gx0, gy0, gx1, gy1);
    gradBack.addColorStop(0.0, 'rgb(0, 60, 120)');
    gradBack.addColorStop(1.0, 'rgb(120, 180, 240)');
    ctx.fillStyle = gradBack;
    // ctx.fillStyle = 'rgb(50, 50, 250)';
    ctx.fillRect(0,0, w, h);

    //if (!this.m_started) {
    //  return;
    //}
    if (this.m_startTime < 0) {
      this.m_startTime = Date.now();
      console.log('start time is stored');
    }

    const timeCur = Date.now();
    const PHASE_LEN = 1000;
    let deltaTime = timeCur - this.m_startTime;
    // console.log(`deltaTime = ${deltaTime}, start = ${this.m_startTime}`);
    let phaseAnim = Math.floor(deltaTime / PHASE_LEN);
    let timeInsidePhase = deltaTime - (phaseAnim * PHASE_LEN);
    let timeRatioInPhase = timeInsidePhase / PHASE_LEN;

    if (phaseAnim > Phase.PHASE_REMOVE_ALL) {
      // repeat anim again
      this.m_startTime = Date.now();
      deltaTime = 0;
      phaseAnim = 0;
      timeInsidePhase = 0;
      timeRatioInPhase = 0.0;
    }


    this.m_phase = phaseAnim;
    this.m_timePhase = timeRatioInPhase;
    // console.log(`phaseAnim = ${phaseAnim}, timeR = ${timeRatioInPhase}`);

    let lightGlobal = 0.0;
    if (phaseAnim === Phase.PHASE_SUN) {
      lightGlobal = timeRatioInPhase;
    } else if (phaseAnim > Phase.PHASE_SUN) {
      lightGlobal = 1.0;
    }


    // render all objects in z - inverse order
    this.renderPhaseSun(ctx, w, h);
    this.renderPhaseMountain(ctx, w, h, lightGlobal);
    this.renderPhaseGrassC(ctx, w, h, lightGlobal);
    this.renderPhaseGrassB(ctx, w, h, lightGlobal);
    this.renderPhaseGrassA(ctx, w, h, lightGlobal);
    this.renderPhaseLowGrass(ctx, w, h);
    this.renderPhaseLeafUp(ctx, w, h);
    this.renderPhaseSystemGrow(ctx, w, h);
    this.renderPhaseLogoTextAppear(ctx, w, h);
  } // end of render

} // end class

export default UiLogoAni;

