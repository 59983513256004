// **********************************************
// Imports
// **********************************************

import React  from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { LANG_ENG }  from '../engine/Lang';
import Sender from '../engine/Sender';
import Crc from '../engine/Crc'

import './UiStyles.css';

const SERVER_REPLY_NOT_SENT = 0;
const SERVER_REPLY_RECEIVED_OK = 1;
const SERVER_REPLY_RECEIVED_FAIL = 2;

// **********************************************
// Class
// **********************************************

class UiOrderForm extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.m_refDetails = null;

    this.onButtonSend = this.onButtonSend.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeSurname = this.onChangeSurname.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeDetails = this.onChangeDetails.bind(this);
    this.onChangeCheck = this.onChangeCheck.bind(this);

    this.state = {
      strName: '',
      strSurname: '',
      strPhone: '',
      strEmail: '',
      strDetails: '',
      agreementChecked: false,
      serverReply: SERVER_REPLY_NOT_SENT,
    };
  } // end of constructor
  getBaseUrl() {
    // lugachai.ru (in ascii + 1)
    const arrLugachaiPtRu = [
      109, 118, 104, 98, 100, 105, 98, 106, 47, 115, 118
    ];
    let i;
    let strLugachai = '';
    for (i = 0; i < arrLugachaiPtRu.length; i++) {
      strLugachai += String.fromCharCode(arrLugachaiPtRu[i] - 1);
    }
    const strBase = 'https://' + strLugachai + '/php/up.php';
    return strBase;
  }
  isValidPhone(strPhone) {
    const strReg = /^\+([\d]+)\(([\d]+)\)([\d]+)/;
    const is = strPhone.match(strReg);
    return is;
  }
  isValidMail(strMail) {
    const strReg = /^\S+@\S+\.\S+/;
    const is = strMail.match(strReg);
    return is;
  }
  checkArgs() {
    if (this.state.strName.length < 1) {
      alert('Very strange looking name.... Try your own name');
      return false;
    }
    if (this.state.strSurname.length < 1) {
      alert('Very strange looking surname.... Try your own surname');
      return false;
    }
    if (this.state.strPhone.length < 7) {
      alert('Very strange looking phone number.... Try your real phone');
      return false;
    }
    if (!this.isValidPhone(this.state.strPhone)) {
      alert('Bad phone format entered.. try +X(YYY)ZZZZZZZZ');
      return false;
    }
    if (this.state.strEmail.length < 4) {
      alert('Very strange looking mail.... Try your real email');
      return false;
    }
    if (!this.isValidMail(this.state.strEmail)) {
      alert('Bad mail format.... Try in form XXXX@YYY.ZZ');
      return false;
    }
    if (this.state.strDetails.length < 4) {
      alert('Very strange message details. Too short.... Try to write something informative');
      return false;
    }
    return true;
  }
  // on send button pressed
  onButtonSend() {
    if (!this.checkArgs()) {
      return;
    }
    const crcEngine = new Crc();

    const strSalt = 'KnbnAga87643';
    const strAll =
      crcEngine.getHtmlFriendlyString(this.state.strName) +
      crcEngine.getHtmlFriendlyString(this.state.strSurname) +
      crcEngine.getHtmlFriendlyString(this.state.strPhone) +
      crcEngine.getHtmlFriendlyString(this.state.strEmail) +
      crcEngine.getHtmlFriendlyString(this.state.strDetails) +
      strSalt;
    const crc = crcEngine.getCrc(strAll);

    // console.log(`arg = ${strAll}, crc = ${crc}`);

    // clear details field
    this.setState({ strDetails: '' });
    this.m_refDetails.value = '';

    const sender = new Sender();

    //const STR_URL = 'http://pilipino.ru/lugachai/up.php' +
    const STR_URL = this.getBaseUrl() +
      '?name=' + crcEngine.getHtmlFriendlyString(this.state.strName) +
      '&surname=' + crcEngine.getHtmlFriendlyString(this.state.strSurname) +
      '&phone=' + crcEngine.getHtmlFriendlyString(this.state.strPhone) +
      '&mail=' + crcEngine.getHtmlFriendlyString(this.state.strEmail) +
      '&details=' + crcEngine.getHtmlFriendlyString(this.state.strDetails) +
      '&code=' + crc;
    // console.log(`STR_URL = ${STR_URL}`);
    sender.send(STR_URL, (strReply) => {
      // console.log(`http reply received: ${strReply}`);
      const STR_GOOD_REPLY = '<p> code ok. message is received</p>';
      if (strReply.indexOf(STR_GOOD_REPLY) > 0) {
        this.setState({ serverReply: SERVER_REPLY_RECEIVED_OK });
      } else {
        this.setState({ serverReply: SERVER_REPLY_RECEIVED_FAIL });
      }
    });
  }
  onChangeName(evt) {
    const strText = evt.target.value;
    this.setState({ strName: strText });
  }
  onChangeSurname(evt) {
    const strText = evt.target.value;
    this.setState({ strSurname: strText });
  }
  onChangePhone(evt) {
    const strText = evt.target.value;
    this.setState({ strPhone: strText });
  }
  onChangeEmail(evt) {
    const strText = evt.target.value;
    this.setState({ strEmail: strText });
  }
  onChangeDetails(evt) {
    const strText = evt.target.value;
    this.setState({ strDetails: strText });
  }
  onChangeCheck(evt) {
    const isChecked = evt.target.checked;
    // console.log(`onChangeCheck. isChechedk=  ${isChecked}`);
    this.setState({ agreementChecked: isChecked });
  }
  render() {
    const langIndex = this.props.lang;
    const strOrderBlank = (langIndex === LANG_ENG) ? 'Order form' : 'Форма заказа';
    const strSend = (langIndex === LANG_ENG) ? 'Send' : 'Отправить';
    const strName = (langIndex === LANG_ENG) ? 'Name' : 'Имя';
    const strSurname = (langIndex === LANG_ENG) ? 'Surname' : 'Фамилия';
    const strPhone = (langIndex === LANG_ENG) ? 'Phone' : 'Телефон';
    const strEmail = (langIndex === LANG_ENG) ? 'Email' : 'Электронная почта';
    const strComment = (langIndex === LANG_ENG) ? 'Details of your request' : 'Напишите подробности вашего заказа в деталях';
    const isTextReady = (this.state.strName.length > 0) && 
      (this.state.strEmail.length > 0) && 
      (this.state.strDetails.length > 0) &&
      (this.state.agreementChecked);
    const jsxButton = (isTextReady) ?
      <Button onClick={this.onButtonSend} active >
        {strSend}
      </Button> : 
      <Button onClick={this.onButtonSend} disabled >
        {strSend}
      </Button>; 

    // eslint-disable-no-multi-str
    const strLabelCheckRus = `Нажимая кнопку «Отправить», я даю свое согласие на
    обработку моих персональных данных, в соответствии с Федеральным законом
    от 27.07.2006 года №152-ФЗ «О персональных данных», на условиях и для целей,
    определенных в Согласии на обработку персональных данных`;
    const strLabelCheckEng = `By clicking the "Send" button, I give my consent to
    processing of my personal data, in accordance with Federal law
    dated July 27, 2006 No. 152-ФЗ “On Personal Data”, on the conditions and for the purposes,
    defined in the Consent to the processing of personal data`;
    const strLabelCheck = (langIndex === LANG_ENG) ?  strLabelCheckEng :  strLabelCheckRus;

    const strTipName = (langIndex === LANG_ENG) ? 'Enter your name' : 'Введите Ваше имя';
    const strTipSurname = (langIndex === LANG_ENG) ? 'Enter your surname' : 'Введите Вашу фамилию';
    const strTipPhone = (langIndex === LANG_ENG) ? 'Enter your phone in format +X(YYY)ZZZZZZZ' : 'Введите Ваш контактный телефон в формате +X(YYY)ZZZZZZZ';
    const strTipMail = (langIndex === LANG_ENG) ? 'Enter your e-mail' : 'Введите Вашу электронную почту';
    const strTipDetails = (langIndex === LANG_ENG) ? 'Enter order details' : 'Введите подробности вашего заказа: что хотите заказать, в каком количестве и так далее';

    const strMsgSentTextOk = (langIndex === LANG_ENG) ? 'Your message was successfully sent' : 'Ваше сообщение успешно отправлено';
    const strMsgSentTextFail = (langIndex === LANG_ENG) ? 'Your message was sent with server error' : 'Ваше сообщение не было отправлено: ошибка сервера';
    let strWasSent = '';
    if (this.state.serverReply === SERVER_REPLY_RECEIVED_OK) {
      strWasSent = strMsgSentTextOk;
    }
    if (this.state.serverReply === SERVER_REPLY_RECEIVED_FAIL) {
      strWasSent = strMsgSentTextFail;
    }

    const jsx = <div>
      <div className="lugachai-head-div" >
        <h1 className="lugachai-head-text">
          {strOrderBlank}
        </h1>
      </div>
      <br />

      <Row><Col className="order-col">
        <Form>
          <Form.Group>
            <Form.Label>
              {strName}
            </Form.Label>
            <Form.Control type="text" placeholder={strTipName} onChange={this.onChangeName} />

            <Form.Label>
              {strSurname}
            </Form.Label>
            <Form.Control type="text" placeholder={strTipSurname} onChange={this.onChangeSurname}  />

            <Form.Label>
              {strPhone}
            </Form.Label>
            <Form.Control type="phone" placeholder={strTipPhone} onChange={this.onChangePhone}  />

            <Form.Label>
              {strEmail}
            </Form.Label>
            <Form.Control type="email" placeholder={strTipMail} onChange={this.onChangeEmail}  />

            <Form.Label>
              {strComment}
            </Form.Label>
            <Form.Control as="textarea" rows="5" placeholder={strTipDetails} 
              onChange={this.onChangeDetails} 
              ref={ (mount) => {this.m_refDetails = mount}} />

            <Form.Check type="checkbox" label={strLabelCheck}  onChange={this.onChangeCheck} />

          </Form.Group>
          {jsxButton}
          <p></p>

          {strWasSent}

        </Form>
      </Col></Row></div>;
    return jsx;
  }
} // class

export default UiOrderForm;