// ********************************************************
// Imports
// ********************************************************

import AnimTools from './AnimTools';

// ********************************************************
// Class
// ********************************************************

/**
 * Class UiLogoAni some text later...
 */
class AnimHand {
  static render(ctx, xc, yc, sizeScale, alpha) {
    const X_PIVOT = 96;
    const Y_PIVOT = 26;
    const pointsShort = [
      2, 26,
      4, 23,
      7, 22,
      13, 22,
      15, 23,
      16, 26,
      16, 59,
      15, 62,
      12, 62,
      7, 62,
      4, 61,
      2, 59
    ];
    const numPointsShort = Math.floor(pointsShort.length / 2);
    const ptsShortNorm = new Array(numPointsShort * 2);
    let i; let j;
    for (i = 0, j = 0; i < numPointsShort; i++, j += 2) {
      const xOld = pointsShort[j + 0];
      const yOld = pointsShort[j + 1];
      const x = (xOld - X_PIVOT) / 100.0;
      const y = (yOld - Y_PIVOT) / 100.0;
      ptsShortNorm[j + 0] = x;
      ptsShortNorm[j + 1] = y;
    }
    const ptsShortTra = new Array(numPointsShort * 2);
    for (i = 0, j = 0; i < numPointsShort; i++, j += 2) {
      const xRot = ptsShortNorm[j + 0];
      const yRot = ptsShortNorm[j + 1];
      const x = xc + sizeScale * xRot;
      const y = yc + sizeScale * yRot;
      ptsShortTra[j + 0] = x;
      ptsShortTra[j + 1] = y;
    }
    const colBlack = { r: 0, g: 0, b: 0, a: alpha };
    const colBlue = { r: 40, g: 120, b: 200, a: alpha };
    const colWhite = { r: 240, g: 240, b: 240, a: alpha };

    const strColorBlack = AnimTools.getRgbaColorString(colBlack);
    const strColorBlue = AnimTools.getRgbaColorString(colBlue);
    const strColorWhite = AnimTools.getRgbaColorString(colWhite);

    ctx.strokeStyle = strColorBlack;
    ctx.fillStyle = strColorBlue;
    ctx.lineWidth = sizeScale * 3 / 100;

    ctx.beginPath();
    for (i = 0, j = 0; i <= numPointsShort; i++, j += 2) {
      if (i === 0) {
        ctx.moveTo(ptsShortTra[j + 0], ptsShortTra[j + 1]);
      } else {
        if (i === numPointsShort) {
          j = 0;
        }
        ctx.lineTo(ptsShortTra[j + 0], ptsShortTra[j + 1]);
      }
    }
    ctx.fill();
    ctx.stroke();

    // hand itself
    const pointsHand = [
      16, 24,
      24, 19,
      36, 13,
      44, 7,
      48, 3,
      51, 2,
      54, 3,
      56, 8,
      56, 12,
      54, 16,
      50, 20,
      69, 19,
      91, 20,
      95, 22,
      96, 24,
      96, 28,
      93, 31,
      56, 34,
      72, 34,
      74, 38,
      74, 42,
      72, 46,
      54, 46,
      66, 47,
      66, 50,
      66, 54,
      62, 58,
      52, 58,
      60, 59,
      60, 62,
      59, 65,
      56, 67,
      32, 68,
      23, 65,
      19, 63,
      16, 61,
    ];
    const numPointsHand = Math.floor(pointsHand.length / 2);
    const ptsHandNorm = new Array(numPointsHand * 2);
    for (i = 0, j = 0; i < numPointsHand; i++, j += 2) {
      const xOld = pointsHand[j + 0];
      const yOld = pointsHand[j + 1];
      const x = (xOld - X_PIVOT) / 100.0;
      const y = (yOld - Y_PIVOT) / 100.0;
      ptsHandNorm[j + 0] = x;
      ptsHandNorm[j + 1] = y;
    }
    const ptsHandTra = new Array(numPointsHand * 2);
    for (i = 0, j = 0; i < numPointsHand; i++, j += 2) {
      const xRot = ptsHandNorm[j + 0];
      const yRot = ptsHandNorm[j + 1];
      const x = xc + sizeScale * xRot;
      const y = yc + sizeScale * yRot;
      ptsHandTra[j + 0] = x;
      ptsHandTra[j + 1] = y;
    }
    ctx.strokeStyle = strColorBlack;
    ctx.fillStyle = strColorWhite;

    ctx.beginPath();
    for (i = 0, j = 0; i <= numPointsHand; i++, j += 2) {
      if (i === 0) {
        ctx.moveTo(ptsHandTra[j + 0], ptsHandTra[j + 1]);
      } else {
        if (i === numPointsHand) {
          j = 0;
        }
        ctx.lineTo(ptsHandTra[j + 0], ptsHandTra[j + 1]);
      }
    }
    ctx.fill();
    ctx.stroke();
  } // end render
} // end class

export default AnimHand;
