

class Sender {
  constructor() {
    this.m_xmlHttp = null;
  }
  send(strUrl, callbackReply) {
    this.m_xmlHttp = new XMLHttpRequest();
    if (!('withCredentials' in this.m_xmlHttp)) {
      alert('This browser not supports CORS requests');
      return;
    }

    this.m_xmlHttp.responseType = 'arraybuffer'; // blob
    this.m_xmlHttp.addEventListener('load', (evt) => {
      const arrBuf = evt.target.response;
      const strBuf = String.fromCharCode.apply(null, new Uint8Array(arrBuf));
      // console.log(`http ready with text: ${strBuf}`);
      callbackReply(strBuf);
    }, false);

    // console.log(`sending http to : ${strUrl}`);
    const NEED_ASYNC = true;
    // const METHOD = 'GET';
    const METHOD = 'GET';
    this.m_xmlHttp.open(METHOD, strUrl, NEED_ASYNC); // true for asynchronous 
    // this.m_xmlHttp.setRequestHeader("Content-type", "application/text");
    this.m_xmlHttp.send();
  } // end send

}

export default Sender;