
// ********************************************************
// Export
// ********************************************************

class Crc {
  constructor() {
    this.m_table = [
      0x0000000, 0x4c11db7, 0x9823b6e, 0xd4326d9, 
      0x130476dc, 0x17c56b6b, 0x1a864db2, 0x1e475005, 
      0x2608edb8, 0x22c9f00f, 0x2f8ad6d6, 0x2b4bcb61, 
      0x350c9b64, 0x31cd86d3, 0x3c8ea00a, 0x384fbdbd, 
      0x4c11db70, 0x48d0c6c7, 0x4593e01e, 0x4152fda9, 
      0x5f15adac, 0x5bd4b01b, 0x569796c2, 0x52568b75, 
      0x6a1936c8, 0x6ed82b7f, 0x639b0da6, 0x675a1011, 
      0x791d4014, 0x7ddc5da3, 0x709f7b7a, 0x745e66cd, 
      0x9823b6e0, 0x9ce2ab57, 0x91a18d8e, 0x95609039, 
      0x8b27c03c, 0x8fe6dd8b, 0x82a5fb52, 0x8664e6e5, 
      0xbe2b5b58, 0xbaea46ef, 0xb7a96036, 0xb3687d81, 
      0xad2f2d84, 0xa9ee3033, 0xa4ad16ea, 0xa06c0b5d, 
      0xd4326d90, 0xd0f37027, 0xddb056fe, 0xd9714b49, 
      0xc7361b4c, 0xc3f706fb, 0xceb42022, 0xca753d95, 
      0xf23a8028, 0xf6fb9d9f, 0xfbb8bb46, 0xff79a6f1, 
      0xe13ef6f4, 0xe5ffeb43, 0xe8bccd9a, 0xec7dd02d, 
    ];
  } // end const
  getHtmlFriendlyString(strInput) {
    let i;
    // replace spec symbols &, +
    let strInp = '';
    for (i = 0; i < strInput.length; i++) {
      const sym = strInput[i];
      if (strInput.charCodeAt(i) < 32) {
        strInp = strInp + '_';
        continue;
      }
      if (sym === '&') {
        strInp = strInp + '.AND.';
      } else if (sym === '+') {
        strInp = strInp + '.PLUS.';
      } else {
        strInp = strInp + sym;
      }
    }
    return strInp;
  }
  getCrc(strInp) {
    let i;
    const len = strInp.length;
    let crc = 9851781;

    const arrNum = [];
    for (i = 0; i < len; i++) {
      const charCode = strInp.charCodeAt(i);
      // console.log(`js arg = ${strInp[i]} code = ${charCode} `);
      if (charCode < 256) {
        arrNum.push(charCode);
      } else {
        arrNum.push(charCode);
      }
    }
    const numDig = arrNum.length;
    //for (i = 0; i < numDig; i++) {
    //  console.log(`arrnum = ${arrNum[i]}`);
    //}


    for (i = 0; i < numDig; i++)
    {
      const charCode = arrNum[i];
      // console.log(`code = ${charCode}`);
      const ind = i & 15;
      const mul = charCode + this.m_table[ind];
      crc = (crc * mul + 987291) & 0x3fffff;
    }
    return crc;
  }
};

export default Crc;
