// ********************************************************
// Imports
// ********************************************************

import React, { Component } from 'react';
import { Nav, Navbar, Container, Button } from 'react-bootstrap';

import UiAbout from './UiAbout';
import UiLang from './UiLang';
import UiCarouselProd from './UiCarouselProd';
import UiOurProduction from './UiOurProduction';
import UiRawTea from './UiRawTea';
import UiOrderForm from './UiOrderForm';
import UiOurTeam from './UiOurTeam';
import UiPhotoGalery from './UiPhotoGalery';
import UiMenu from './UiMenu';
import { LANG_ENG, LANG_RUS }  from '../engine/Lang';

import Crc from '../engine/Crc';

// ********************************************************
// Class
// ********************************************************

class UiNavBar extends Component {
  constructor(props) {
    super(props);

    this.onHide = this.onHide.bind(this);
    this.onBrand = this.onBrand.bind(this);
    this.onLang = this.onLang.bind(this);

    // detect language
    // src: ru-RU or en-US or fr/FR ...
    // get first 2 letters
    const userLang = (navigator.language || navigator.userLanguage).substring(0, 2); // 
    // console.log(`The language is: ${userLang}`);
    const langDefault = (userLang === 'ru') ? LANG_RUS : LANG_ENG;

    this.state = {
      showAbout: false,
      lang: langDefault,
    };
    // this.testCrc('a');
    // this.testCrc('ab');
    // this.testCrc('abc');
    // this.testCrc('абв');
    // this.testCrc('абб');
    // this.testCrc('абг');
    // this.testCrc('абвг');
  }
  testCrc(str)
  {
    const crcEngine = new Crc();
    const crc = crcEngine.getCrc(str);
    console.log(`crc(${str}) = ${crc}`);
  }
  onLang(langIndex) {
    // console.log(`onLangUiNavBar: lang = ${langIndex}`);
    this.setState({ lang: langIndex });
  }
  onHide() {
    this.setState({ showAbout: false });
  }
  onBrand() {
    // console.log('click brand');
    this.setState({ showAbout: true });
  }
  render() {
    const langIndex = this.state.lang;
    const strMessageOnMenu = (langIndex === LANG_ENG) ? 'Lugachai: excellent quality ivan-tea' : 'Лугачай: Иван-чай высшего качества';
    const strAbout = (langIndex === LANG_ENG) ? 'About company' : 'О компании';

    const jsxNavBar = 
      <Container fluid="true" style={{ height:'100%', minHeight:'100%' }}  >
        
        <Navbar bg="light" variant="light" expand="lg" >
          <div>
            <Nav className="mr-auto">
              <Button onClick={this.onBrand}>
                {strAbout}
              </Button>

              <Navbar.Text className="d-none d-sm-block">
                {strMessageOnMenu}
              </Navbar.Text>
            </Nav>
          </div>
          <UiLang onLang={this.onLang} lang={langIndex}  />
        </Navbar>

        <UiCarouselProd lang={langIndex} />
        <UiMenu lang={langIndex} />
        <UiOurProduction lang={langIndex} />
        <UiRawTea lang={langIndex} />
        <UiPhotoGalery lang={langIndex} />
        <UiOurTeam lang={langIndex} />
        <UiOrderForm lang={langIndex} />

        <UiAbout stateVis={this.state.showAbout} onHide={this.onHide} lang={langIndex}  />

      </Container>;
    return jsxNavBar;
  }
}

export default UiNavBar;
