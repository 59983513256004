// **********************************************
// Imports
// **********************************************

import React  from 'react';
import { Image, Row, Col } from 'react-bootstrap';
import { LANG_ENG }  from '../engine/Lang';

import './UiStyles.css';

// **********************************************
// Class
// **********************************************

class UiOurTeam extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.m_arrTeam = [
      {
        img: 'images/team_alice.jpg',
        nameRus: 'Алиса',
        nameEng: 'Alice',
        roleRus: 'Тоже член семьи',
        roleEng: 'Important family member',
      },
      {
        img: 'images/team_lelya.jpg',
        nameRus: 'Леля',
        nameEng: 'Lilian',
        roleRus: 'Лучший следопыт',
        roleEng: 'Best path finder',
      },
      {
        img: 'images/team_masha.jpg',
        nameRus: 'Маша',
        nameEng: 'Masha',        
        roleRus: 'Опытный дегустатор',
        roleEng: 'Experienced smell tester',
      },
    ];
  } // end constr
  render() {
    const langIndex = this.props.lang;
    const strTeamTitle = (langIndex === LANG_ENG) ? 'Our team' : 'О нашей команде';
    const strTeamDetails = (langIndex === LANG_ENG) ?
      'We are proud to present our family business. First of all, we make wonderful Ivan-tea for ourselves. All our family members are involved in the manufacturing.' :
      'Мы рады вам представить свой семейный бизнес. Мы делаем прекрасный иван-чай прежде всего для себя. Все члены нашей семьи принимают участие в изготовлении продукта';
    const strFamilyHobby = (langIndex === LANG_ENG) ? 'This is family hobby' : 'Это семейное хобби';

    // Col
    // xl lg md="3"

    const jsxRet = <div>
      <div className="lugachai-head-div" >
        <h1 className="lugachai-head-text">
          {strTeamTitle}
        </h1>
      </div>
      <br />

      <p>
        {strTeamDetails}
      </p>
      <h2 className="text-center">
        {strFamilyHobby}
      </h2>

      <Row>
        {this.m_arrTeam.map(  (d,i) => {
          const strKey = `id_${i}`;
          const strImage = d.img;
          const strRole = (langIndex === LANG_ENG) ? d.roleEng : d.roleRus;
          const strName = (langIndex === LANG_ENG) ? d.nameEng : d.nameRus;
          return <Col key={strKey} xl xs lg md={4}>
            <Image fluid src={strImage} alt="alt" />
            <p className="text-center our-team-role">
              {strRole}
            </p>
            <p className="text-center our-team-name">
              {strName}
            </p>

          </Col>;
        })}
      </Row>
      <br />
    </div>;
    return jsxRet;
  } // render
}

export default UiOurTeam;
