
// ********************************************************
// Export
// ********************************************************

const LANG_ENG = 0;
const LANG_RUS = 1;

export {
  LANG_ENG, 
  LANG_RUS,
}