// **********************************************
// Imports
// **********************************************

import React  from 'react';
import { Image, Row, Col } from 'react-bootstrap';
import { LANG_ENG }  from '../engine/Lang';

import './UiStyles.css';

// **********************************************
// Class
// **********************************************

class UiMenu extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.m_arrMenu = [
      {
        img: 'images/render_hut.png',
        nameRus: 'Чай сказочный',
        nameEng: 'Fairy tales tea',
        detailsRus: 'Иван-чай, изготовленный из листов кипрея, ферментированный, в сувенирной упаковке домик',
        detailsEng: 'Fermented Ivan tea made from fireweed sheets in a hut shaped souvenir box',
        priceRub: '500',
      },
      {
        img: 'images/render_hb_penta.png',
        nameRus: 'Иван чай липовый',
        nameEng: 'Ivan chai lime',
        detailsRus: 'Содержит ферментированный лист кипрея, цветы липы',
        detailsEng: 'Contains a fermented leaf of kiprey and linden flowers',
        priceRub: '550',
      },
      {
        img: 'images/render_heart.png',
        nameRus: 'Иван-чай с любовью',
        nameEng: 'Ivan chai with love',
        detailsRus: 'Иван-чай в сувенирной упаковке в виде сердца',
        detailsEng: 'Ivan tea in a souvenir packaging in the heart shape',
        priceRub: '700',
      },
      {
        img: 'images/render_heart_red.png',
        nameRus: 'Иван-чай с любовью',
        nameEng: 'Ivan chai with love',
        detailsRus: 'Иван-чай в сувенирной упаковке в виде сердца',
        detailsEng: 'Ivan tea in a souvenir packaging in the heart shape',
        priceRub: '700',
      },
      {
        img: 'images/render_cat.png',
        nameRus: 'Иван-чай кис кис',
        nameEng: 'Ivan chai myao myao',
        detailsRus: 'Иван-чай в сувенирной упаковке кис кис',
        detailsEng: 'Ivan tea in a souvenir box myao myao',
        priceRub: '500',
      },
      {
        img: 'images/render_bear.png',
        nameRus: 'Иван-чай добрый мишка',
        nameEng: 'Ivan chai friendly bear',
        detailsRus: 'Иван-чай добрый мишка',
        detailsEng: 'Ivan tea in a bear shaped box',
        priceRub: '500',
      },
      {
        img: 'images/render_hex.png',
        nameRus: 'Иван-чай цветочный',
        nameEng: 'Ivan chai with flowers',
        detailsRus: 'Элитный Иван-чай изготовленный исключительно из цветов Кипрея, собранных в период цветения',
        detailsEng: 'Elite Ivan tea made exclusively from the flowers of Kiprey, collected during the flowering period',
        priceRub: '2000',
      },
      {
        img: 'images/render_green_penta.png',
        nameRus: 'Иван-чай мятный',
        nameEng: 'Ivan chai with mint',
        detailsRus: 'Иван-чай с добавлением листьев мяты',
        detailsEng: 'Ivan tea with mint leafes addition',
        priceRub: '550',
      },
      {
        img: 'images/render_mag_penta.png',
        nameRus: 'Иван-чай витаминный',
        nameEng: 'Ivan chai with vitamins',
        detailsRus: 'Содержит ферментированный лист кипрея, цветы календулы',
        detailsEng: 'Contains a fermented leaf of Kiprey and marigold flowers',
        priceRub: '550',
      },
      {
        img: 'images/render_church.png',
        nameRus: 'Иван-чай залог здоровья',
        nameEng: 'Ivan chai for strong health',
        detailsRus: 'Содержит ферментированный лист кипрея, цветы липы, клевера, лист смородины',
        detailsEng: 'Contains a fermented leaf of Kiprey(fireweed), linden flowers, clover, currant leafes',
        priceRub: '600',
      },

    ];
  } // end constr
  render() {
    const langIndex = this.props.lang;
    const strMenuTitle = (langIndex === LANG_ENG) ? 'Menu' : 'Меню';
    const strOurOffers = (langIndex === LANG_ENG) ? 'We offer' : 'Наши предложения';

    const jsxRet = <div>
      <div className="lugachai-head-div" >
        <h1 className="lugachai-head-text">
          {strMenuTitle}
        </h1>
      </div>
      <div className="text-center menu-title-details">
        {strOurOffers}
      </div>
      <br />

      <Row>
        {this.m_arrMenu.map(  (d,i) => {
          const strKey = `id_${i}`;
          const strImage = d.img;
          const strName = (langIndex === LANG_ENG) ? d.nameEng : d.nameRus;
          const strDetails = (langIndex === LANG_ENG) ? d.detailsEng : d.detailsRus;
          const strRub = (langIndex === LANG_ENG) ? ' rub' : ' руб';
          const strPrice = d.priceRub.concat(strRub);
          const jsxBody = <Col key={strKey} xl={5} xs={5} lg={4} md={5}>
            <Row>
              <Col xl={3} lg={3} md={3}>
                <div className="text-right">
                  <Image fluid src={strImage} alt="alt" />
                </div>
              </Col>
              <Col xl={9} lg={9} md={9}>
                <Row>
                  <Col xl={9} lg={9} md={9} className="menu-title">
                    {strName}
                  </Col>
                  <Col xl={3} lg={3} md={3} className="menu-price text-center">
                    {strPrice}
                  </Col>
                </Row>
                <Row>
                  <Col xl={12} lg={12} md={12}>
                    {strDetails}
                  </Col>
                </Row>
              </Col>
            </Row>
            { /*
            <div className="text-center">
              <Image fluid src={strImage} alt="alt" />
            </div>
            
            <p className="text-center our-team-name">
              {strName}
            </p>
            <p className="text-center our-team-role">
              {strDetails}
            </p>
            <p className="text-center menu-price">
              {strPrice}
            </p>
            */ }
          </Col>;
          const isRowStart = ((i & 1) === 0);
          const jsxRet = (isRowStart) ?
            <> <Col xl={1} lg={1} ></Col>{jsxBody} </> :
            <> {jsxBody}<Col xl={1} lg={1} ></Col> </>;
          // return jsxBody;
          return jsxRet;
        })}
      </Row>
      <br />
    </div>;
    return jsxRet;
  } // render
}

export default UiMenu;
