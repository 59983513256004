// **********************************************
// Imports
// **********************************************

import React  from 'react';
// import { Image, Row, Col } from 'react-bootstrap';

import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";


import { LANG_ENG }  from '../engine/Lang';

import './UiStyles.css';

// **********************************************
// Class
// **********************************************

class UiPhotoGallery extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.m_arrPhotos = [
      {
        original: "images/gal_hut.jpg",
        thumbnail: "images/gal_hut_100x67.jpg",
        description: "Funny hut / Избушка",
      }, {
        original: "images/gal_heart_blue.jpg",
        thumbnail: "images/gal_heart_blue_100x67.jpg",
        description: "Blue heart / Голубое сердце",
      }, {
        original: "images/gal_window_heart_pentas.jpg",
        thumbnail: "images/gal_window_heart_pentas_100x67.jpg",
        description: "Boxes on the windowsill / Чайные коробочки на подоконнике",
      }, {
        original: "images/gal_table_5_boxes.jpg",
        thumbnail: "images/gal_table_5_boxes_100x67.jpg",
        description: "Five boxes on the table / Пять коробочек на столе",
      }, {
        original: "images/gal_hut_on_stone.jpg",
        thumbnail: "images/gal_hut_on_stone_100x67.jpg",
        description: "Hut shaped box in grass / Коробочка-избушка в траве",
      }, {
        original: "images/gal_penta_mag_on_stone.jpg",
        thumbnail: "images/gal_penta_mag_on_stone_100x67.jpg",
        description: "Penta shaped box on stone / Коробочка-пятигранник на камне",
      }, {
        original: "images/gal_penta_green.jpg",
        thumbnail: "images/gal_penta_green_100x67.jpg",
        description: "Green penta shaped box / Зеленая кооробка-пятигранник",
      }, {
        original: "images/gal_cat_white_flowers.jpg",
        thumbnail: "images/gal_cat_white_flowers_100x67.jpg",
        description: "Cat shaped box in flowers / Коробка-котик в цветах",
      }, {
        original: "images/gal_kiz_white_flowers.jpg",
        thumbnail: "images/gal_kiz_white_flowers_100x67.jpg",
        description: "Church shaped box in flowers / Коробка-храм в цветах",
      }, {
        original: "images/gal_red_heart_white_flowers.jpg",
        thumbnail: "images/gal_red_heart_white_flowers_100x67.jpg",
        description: "Red heart shaped box / Коробочка алое сердце",
      }, {
        original: "images/gal_hexa_white_flowers.jpg",
        thumbnail: "images/gal_hexa_white_flowers_100x67.jpg",
        description: "Hexa shaped box in flowers / Шестигранная коробочка в цветах",
      }];
  } // end constr
  render() {
    const langIndex = this.props.lang;
    const strPhotoGalleryTitle = (langIndex === LANG_ENG) ? 'Photo gallery' : 'Фото галерея';

    const jsxPhotoGallery = <div>
      <div className="lugachai-head-div" >
        <h1 className="lugachai-head-text">
          {strPhotoGalleryTitle}
        </h1>
      </div>
      <br />
      <div>
        <ImageGallery 
          items={this.m_arrPhotos} 
          sumbnailPosition={'top'}
          showBullets={true}
          showIndex={true}
          autoPlay={true}
          showFullscreenButton={false} />
      </div>
    </div>;
    return jsxPhotoGallery;

    /*
    const jsxRet = <div>
      <div className="lugachai-head-div" >
        <h1 className="lugachai-head-text">
          {strTitle}
        </h1>
      </div>
      <br />
      <Row>
        {this.m_arrGallery.map(  (d,i) => {
          const strKey = `id_${i}`;
          const strImage = d.img;
          const jsxColImg = <Col key={strKey} xl xs lg md={4}>
            <Image fluid src={strImage} alt="alt" />
          </Col>;
          return jsxColImg;
        })}
      </Row>
      <br />
    </div>;
    return jsxRet;
    */
  } // render
}

export default UiPhotoGallery;
