// ********************************************************
// Imports
// ********************************************************

import AnimTools from './AnimTools';

// ********************************************************
// Class
// ********************************************************

/**
 * Class AnimLuga
 */
class AnimLuga {
  static render(ctx, xc, yc, sizeScale, alpha) {
    const X_PIVOT = 106;
    const Y_PIVOT = 36;
    const LSIZE = 200;
    const pointsL = [
      21, 6,
      23, 18,
      24, 29,
      23, 40,
      19, 51,
      14, 59,
      9, 63,
      3, 63,
      9, 66,
      16, 66,
      23, 63,
      32, 59,
      43, 57,
      52, 59,
      59, 62,
      62, 65
    ];
    const pointsU = [
      71, 27,
      71, 44,
      72, 58,
      75, 64,
      78, 66,
      79, 67,
      83, 66,
      87, 65,
      93, 59,
      97, 51,
      98, 39,
      97, 30,
      94, 21,
      88, 11,
      79, 4,
    ];
    const pointsG = [
      139, 5,
      130, 5,
      123, 7,
      118, 13,
      114, 19,
      112, 27,
      112, 35,
      114, 45,
      119, 53,
      124, 58,
      132, 62,
      141, 65,
      148, 66,
      146, 62,
      142, 55,
      139, 48,
      139, 39,
      140, 29,
      139, 22,
    ];
    const pointsA = [
      164, 61,
      161, 54,
      161, 45,
      165, 35,
      169, 25,
      171, 17,
      172, 12,
      171, 7,
      176, 13,
      180, 15,
      186, 17,
      189, 19,
      189, 27,
      190, 38,
      189, 48,
      188, 51,
      185, 58,
      188, 51,
      178, 46,
      167, 40
    ];

    const pointAll = [
      pointsL, pointsU, pointsG, pointsA
    ];
    const NUM_LETTERS = pointAll.length;

    const MAX_POINTS = 40;
    const ptsNorm = new Array(MAX_POINTS * 2);
    const ptsTra = new Array(MAX_POINTS * 2);

    let l;
    for (l = 0; l < NUM_LETTERS; l++) {
      const pointsLetter = pointAll[l];
      const numPoints = Math.floor(pointsLetter.length / 2);
      let i; let j;
      for (i = 0, j = 0; i < numPoints; i++, j += 2) {
        const xOld = pointsLetter[j + 0];
        const yOld = pointsLetter[j + 1];
        const x = (xOld - X_PIVOT) / LSIZE;
        const y = (yOld - Y_PIVOT) / LSIZE;
        ptsNorm[j + 0] = x;
        ptsNorm[j + 1] = y;
      }
      for (i = 0, j = 0; i < numPoints; i++, j += 2) {
        const xRot = ptsNorm[j + 0];
        const yRot = ptsNorm[j + 1];
        const x = xc + sizeScale * xRot;
        const y = yc + sizeScale * yRot;
        ptsTra[j + 0] = x;
        ptsTra[j + 1] = y;
      }
      const colWhite = { r: 240, g: 240, b: 240, a: alpha };
      const strColorWhite = AnimTools.getRgbaColorString(colWhite);
      ctx.strokeStyle = strColorWhite;
      ctx.lineWidth = sizeScale * 8 / LSIZE;
      ctx.lineCap = 'round';

      ctx.beginPath();
      for (i = 0, j = 0; i < numPoints; i++, j += 2) {
        if (i === 0) {
          ctx.moveTo(ptsTra[j + 0], ptsTra[j + 1]);
        } else {
          ctx.lineTo(ptsTra[j + 0], ptsTra[j + 1]);
        }
      }
      ctx.stroke();
  
    } // for l all letters
  } // end render
} // end class

export default AnimLuga;
