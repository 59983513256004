

class AnimTools {
  static colorInterpolate(colDst, colA, colB, t) {
    const t1 = 1.0 - t;
    colDst.r = Math.floor(colA.r * t1 + colB.r * t);
    colDst.g = Math.floor(colA.g * t1 + colB.g * t);
    colDst.b = Math.floor(colA.b * t1 + colB.b * t);
  }
  static getColorString(col) {
    const str = 'rgb(' + col.r.toString() + ',' + col.g.toString() + ',' + col.b.toString() + ')';
    return str;
  }
  static getRgbaColorString(col) {
    const str = 'rgba(' + col.r.toString() + ',' + col.g.toString() + ',' +
      col.b.toString() + ',' + col.a.toFixed(2) + ')';
    return str;
  }
  static rotateVec(vec, angle) {
    const ca = Math.cos(angle);
    const sa = Math.sin(angle);
    const x = vec.x * ca + vec.y * (-sa);
    const y = vec.x * sa + vec.y * ca;
    vec.x = x; vec.y = y;
  }
  static addVec(vDst, vSrc1, vSrc2) {
    vDst.x = vSrc1.x + vSrc2.x;
    vDst.y = vSrc1.y + vSrc2.y;
  }
  static bezierGetPoint(v0, v1, v2, v3, t, vDst) {
    const t1 = 1.0 - t;
    vDst.x = v0.x * t1 * t1 * t1 + 3.0 * v1.x * t * t1 * t1 + 3.0 * v2.x * t * t * t1 + v3.x * t * t * t;
    vDst.y = v0.y * t1 * t1 * t1 + 3.0 * v1.y * t * t1 * t1 + 3.0 * v2.y * t * t * t1 + v3.y * t * t * t;
  }
  static distanceBetween(v0, v1) {
    const dx = v1.x - v0.x;
    const dy = v1.y - v0.y;
    const d = Math.sqrt(dx * dx + dy * dy);
    return d;
  }
};

export default AnimTools;
